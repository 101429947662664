import { combineReducers } from 'redux';

// Dummy Reducer 1: exampleReducer
const exampleReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

// Dummy Reducer 2: anotherReducer
const anotherReducer = (state = { status: 'idle' }, action) => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

// Root Reducer: Combine all reducers here
const rootReducer = combineReducers({
  example: exampleReducer,
  another: anotherReducer,
});

export default rootReducer;
