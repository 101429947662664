import React from 'react';
import { Link } from "react-router-dom";
import './footer.css'

const Footer = () => {

    return (
        <footer id='footer-container'>
            <br />
            <br />
            <div id='small-info-block'>
                © 2024 Adam Scott Labs. All rights reserved&nbsp;
                <Link to='/privacy-policy' className='general-link-text'>
                    Privacy Policy
                </Link>
            </div>
        </footer>
    )
}


export default Footer;
