import React from 'react';
import { Link } from "react-router-dom";
import './navbar.css'

const NavBar = () => {
    return (
            <nav>
            <Link to='/' >
                <img src={require('../../assets_and_styling/images/logo_and_banners/logo_banner_2.png')} alt="robot logo" id='logo-banner' />
            </Link>
                <Link to="/core-platform" className='general-link-text' >
                    <h5>Core Platform</h5>
                </Link>
                |
                <Link className='general-link-text' to='/contact-us'>
                    <h5>
                        Contact Us
                    </h5>
                </Link>
                |
                <Link className='general-link-text' to='/about-us'>
                    <h5>
                        About Us
                    </h5>
                </Link>
                |
                <h5 className='general-link-text'>
                    <a href="https://www.linkedin.com/company/extract-experts/about/" target="_blank" rel="noreferrer noopener"  > LinkedIn</a>
                </h5>
                |
                <Link className='general-link-text' to="/careers">
                    <h5>
                        Careers
                    </h5>
                </Link>
            </nav>
    )
}


export default NavBar;
